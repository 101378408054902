import React from 'react'
import Oux from '../../hoc/Oux/Oux'
import './Form8821Contract.css'
import Spinner from '../UI/Spinner/Spinner'
import storage from '../../utility/storage'

const form8821Contract = props => {
    let step = null
    if (props.contractSigned) {
        step = (
            (
                <Oux>
                    <div className="container-login100 container-fluid form_8821_contract_wrapper no_hero_full">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 wow zoomIn">
                                <div class="tc_inner_wrap">
                                    <div class="tc_inner_content" style={{ width: '100%' }}>
                                        {/* <header class="theme_header">
                                            <h3 class="h3_title">Thank You</h3>
                                        </header> */}
                                        <div class="custom_card">
                                            <div class="card_head"></div>
                                            <div class="card_body">
                                                <div class="user_profile_8821_form media">
                                                    {/* <div class="media_img media_circle">
                                                    <img src="/images/freetax/1.png" class="inner_image" alt="..." />
                                                </div> */}
                                                    <div class="media-body">
                                                        <h5 class="media_title text-center">Your 8821 form is Signed</h5>
                                                        {/* <p class="media_info">
                                                        Thank You.
                                                        </p> */}
                                                    </div>
                                                </div>
                                                {/* <div class="tc_msg media">
                                                <div class="media_img tc_msg_img">
                                                    <img src="/images/freetax/next (1).svg" class="inner_image" alt="..." />
                                                </div>
                                                <div class="media-body">
                                                    <p class="tc_msg_info">
                                                        You are about to be directed to Docusign, a secure an confidential
                                                        method to sign on IRS Tax Authorization Form 8821.This form allows
                                                        IRS licensed individuals and IRS verified organization like Community
                                                        Tax to obtain your confidential tax to history in order to generate
                                                        your personalized tas report.Again, this form only allows us to
                                                        access tax information on file with the IRS.We will keep your
                                                        information secure and confidential.
                                                        </p>
                                                </div>
                                            </div> */}
                                                <div class="card_footer">
                                                    {
                                                        props.isLoading
                                                            ? <a href="javascript:void(0)" style={{ opacity: '0.5' }} disabled={true} class="tc_anchor">
                                                                <span class="align-middle">Please wait...</span>
                                                            </a>
                                                            : <a href="javascript:void(0)" onClick={() => {
                                                                props.logoutUser({
                                                                    caseid: storage.get("case_id", null),
                                                                    userid: storage.get("userid", null),
                                                                    type: 'Logout'
                                                                })
                                                            }} class="tc_anchor">
                                                                <div class="sign_form_icon login_icon">
                                                                    <img src="/images/freetax/log-in.svg" class="inner_image" alt="sign_form" />
                                                                </div>
                                                                <span class="align-middle">Click Here to Logout</span>
                                                            </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card_footer text-center">
                                            <a href="javascript:void(0)" onClick={props.navigateUserToHome} class="take_me_to_home_btn mt-2">
                                                Take Me To Home Page
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Oux>
            )
        )
    }
    else {
        step = (
            <Oux>
                <div class="clearfix"></div>
                <div className="container-login100 container-fluid form_8821_contract_wrapper no_hero_full">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 wow zoomIn">
                            <div class="tc_inner_wrap">
                                <div class="tc_inner_content">
                                    <header class="theme_header">
                                        <h3 class="h3_title">WHAT'S NEXT?</h3>
                                    </header>
                                    <div class="custom_card">
                                        <div class="card_head"></div>
                                        <div class="card_body">
                                            <div class="user_profile_8821_form media">
                                                <div class="media_img media_circle">
                                                    <img src="/images/freetax/hoefke1.jpg" class="inner_image" alt="..." />
                                                </div>
                                                <div class="media-body">
                                                    <h5 class="media_title">Hi, my name is Michael Hoefke</h5>
                                                    <p class="media_info">
                                                        I am an Enrolled Agent licensed to practice before the internal
                                                        Revenue Service in accordance with Circular 230.Freetaxreport.com in
                                                        powered by FinancePal in
                                                        conjunction with Community Tax,nationwide leader is professional tax
                                                        services.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="tc_msg media">
                                                <div class="media_img tc_msg_img">
                                                    <img src="/images/freetax/next (1).svg" class="inner_image" alt="..." />
                                                </div>
                                                <div class="media-body">
                                                    <p class="tc_msg_info">
                                                        You are about to be directed to Docusign, a secure an confidential
                                                        method to sign on IRS Tax Authorization Form 8821.This form allows
                                                        IRS licensed individuals and IRS verified organization like Community
                                                        Tax to obtain your confidential tax to history in order to generate
                                                        your personalized tas report.Again, this form only allows us to
                                                        access tax information on file with the IRS.We will keep your
                                                        information secure and confidential.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card_footer">
                                                {
                                                    props.isLoading
                                                        ? <a href="javascript:void(0)" style={{ opacity: '0.5' }} disabled={true} class="tc_anchor">
                                                            <span class="align-middle">Please wait...</span>
                                                        </a>
                                                        : <a href="javascript:void(0)" onClick={props.signDocument} class="tc_anchor">
                                                            <div class="sign_form_icon login_icon">
                                                                <img src="/images/freetax/log-in.svg" class="inner_image" alt="sign_form" />
                                                            </div>
                                                            <span class="align-middle">Proceed to Sign Form</span>
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Oux>
        )
    }
    return step
}

export default form8821Contract