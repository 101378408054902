import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from '../../utility/constants/constants';
import { withRouter } from 'react-router';
import $ from 'jquery'
const Sidebbar = (props) => {
    let currentRoute = props.history.location.pathname;

    let tabs = [{ title: "Home", active: currentRoute === routes.HOME, activeIcon: '/images/icons/icn_home_active.svg', icon: '/images/icons/icn_home.png', route: routes.HOME },
    { title: "Documents", active: currentRoute === routes.DOCUMENTS, activeIcon: '/images/icons/icn_doc_active.svg', icon: '/images/icons/icn_doc.svg', route: routes.DOCUMENTS },
    { title: "Billing", active: currentRoute === routes.BILLING, activeIcon: '/images/icons/icn_billing_active.svg', icon: '/images/icons/icn_billing.svg', route: routes.BILLING },
    { title: "Contact Us", active: currentRoute === routes.CONTACT_US, activeIcon: '/images/icons/icn_contact_active.svg', icon: '/images/icons/icn_contact.svg', route: routes.CONTACT_US },
    { title: "Notifications", active: currentRoute === routes.NOTIFICATIONS, activeIcon: '/images/icons/icn_notify_active.svg', icon: '/images/icons/icn_notify.svg', route: routes.NOTIFICATIONS },
    { title: "My Account", active: currentRoute === routes.MYACCOUNT, activeIcon: '/images/icons/icn_account_active.svg', icon: '/images/icons/icn_account.svg', route: `${routes.MYACCOUNT}${routes.PERSONAL_INFORMATION}` }];

    const toggleSidebar = () => {
        if (window.innerWidth < 768) {
            $('#sidebar').toggleClass('active');
            $('body').toggleClass('tb_sidebar_active');
            $('.main_section').toggleClass('active');
        }
    }

    return (
        <aside id="sidebar" className="sidebar_block mob_sidebar">
            <div className="hoverable_sidebar scrollbar" id="right">
                <ul className="list-unstyled components">
                    {
                        tabs.map((ele, index) =>
                            <li className={ele.active ? "active" : ""} key={index}>
                                <NavLink to={ele.route} onClick={toggleSidebar}>
                                    <span className="icn_lnr lnr_home">
                                        <img src={ele.activeIcon} alt="" className="icn_active" />
                                        <img src={ele.icon} alt="" className="icn_non_active" />
                                    </span>
                                    <span className="fp_sidebar_link_text">{ele.title}</span>
                                </NavLink>
                            </li>
                        )
                    }
                </ul>
            </div>
        </aside>
    );
}

export default withRouter(Sidebbar);
