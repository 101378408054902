import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRouter from './router/router';
import { removeConsoleLog } from './utility/utility';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import RouteChangeListener from './utility/RouteChangeListener';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import * as miscActions from './redux/actions/usersActions/miscAction';
import Oux from './hoc/Oux/Oux';
import { animateSidebar } from './styles/js/custom';
import { BASE_URL } from './config';
import HttpsRedirect from 'react-https-redirect'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount = () => {
    animateSidebar();
    if (process.env.REACT_APP_ENV === 'production') {
      removeConsoleLog();
    }
    this.props.addHistory(this.props.history);
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.user && this.props.user) {
      animateSidebar();
    }
  }

  render() {
    return (
      <HttpsRedirect>
        <RouteChangeListener />
        <ReactNotification />
        <AppRouter {...this.props} />
      </HttpsRedirect>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  isLoggedIn: state.authReducer.isLoggedIn,
  isFreeTaxRedirected: state.authReducer.isFreeTaxRedirected,
  freeTaxChangePasswordStatus: state.authReducer.freeTaxChangePasswordStatus,
  freeTax8821ContractStatus: state.authReducer.freeTax8821ContractStatus,
  freeTaxNumberVerifiedStatus: state.authReducer.freeTaxNumberVerifiedStatus
});

const mapStateToDispatch = (dispatch) => ({
  addHistory: (history) => dispatch(miscActions.addHistory(history)),
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(App));
